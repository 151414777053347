import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';

export default function LoanNotices(props) {
  const loanData = props.loanData;
  const [noticeArray, setNoticeArray] = useState([]);
  const [warningArray, setWarningArray] = useState([]);

  const currentDate = new Date();
  //const appDate = new Date(loanData[ 'Application Date' ]);
  const closeDate = new Date(loanData[ 'Est Closing Date' ]);
  let daysToClose = getDaysRemaining(closeDate);
  let cdReady = false;
  let noticeID = 0;
  let warnID = 0;
  var daysLeft = 0;
  var messageTxt = '';
  var tempNoticeArray = [];
  var tempWarningArray = [];

  useEffect(() => {
    checkLoanRules();
    //console.log("Temp notice array", tempNoticeArray);
    //console.log("Temp warning array", tempWarningArray);
    setNoticeArray(tempNoticeArray);
    setWarningArray(tempWarningArray);
  }, []);

  // Utility functions
  function getDaysRemaining(date) {
    let totalDays = "";

    if (date !== "") {
      let closingDate = new Date(date);
      //console.log("date currentDate: ", currentDate);
      //console.log("date closingDate: ", closingDate);
      // calculating total time difference
      let timeDifference = closingDate.getTime() - currentDate.getTime();
      totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
      //console.log("date totalDays: ", totalDays);
      if (totalDays < 0) { 
        totalDays = totalDays * -1 + ' days passed date';
      }
      //console.log("Number of days till close: ", totalDays);
    } else {
      totalDays = "TBD";
    }
    
    return totalDays;
  }

  function getDayDiff(date) {
    let dayDiff = 0;

    if (date !== "") {
      let currentDate = new Date();
      let compareDate = new Date(date);
      
      //console.log("Current date: ", currentDate);
      //console.log("Compare date: ", compareDate);


        let timeDifference = compareDate.getTime() - currentDate.getTime();
        let calcDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        dayDiff = calcDays;
        //console.log("daydiff: ", dayDiff);
    }
    
    return dayDiff;
  }

  // Check Lock Expiration Date
  function checkLockExp() {
    //console.log("checkLockExp");
    if (loanData["Lock Expiration Date"].length !== 0 && loanData["Milestone Date - Funding"].length === 0) {
      let noticeDaysLimit = 5;
      let alertDaysLimit = 3;
      let lockDaysLeft = 0;
      let lockExpDate = new Date(loanData["Lock Expiration Date"]);
      //console.log("lockExpDate: ", lockExpDate);

      lockDaysLeft = getDayDiff(lockExpDate);
      //console.log("lockDaysLeft: ", lockDaysLeft);

      if (lockDaysLeft > noticeDaysLimit) {
        return;
      } else
      if (lockDaysLeft <= noticeDaysLimit && lockDaysLeft > alertDaysLimit) {
        messageTxt = 'Lock is expiring in ' + lockDaysLeft + ' days';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else if (lockDaysLeft <= alertDaysLimit && lockDaysLeft >= 1) {
        messageTxt = 'Lock is expiring in ' + lockDaysLeft + ' day' + (lockDaysLeft !== 1 ? "s" : "");
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (lockDaysLeft === 0) {
        messageTxt = 'Lock expires today';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else {
        lockDaysLeft = lockDaysLeft * -1;
        messageTxt = 'Lock has expired by ' + lockDaysLeft + ' day' + (lockDaysLeft !== 1 ? "s" : "");
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check RESPA
  function checkRESPA() {
    //("checkRESPA");
    if (loanData[ 'TIL Intl Disclosure Provided Date' ].length === 0) {
      let dueDate = loanData['Initial Disclosure Due Date']
      let daysLeft = getDayDiff(dueDate);
  
      //console.log("Respa Day diff: ",daysLeft );
      if (daysLeft > 1) {
        messageTxt = 'RESPA docs are due in ' + daysLeft + ' days';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else if (daysLeft === 1) {
        messageTxt = 'RESPA docs are due in ' + daysLeft + ' day';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (daysLeft === 0) {
        messageTxt = 'RESPA docs are due today';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else {
        daysLeft = daysLeft * -1;
        messageTxt = 'RESPA docs are ' + daysLeft + ' day' + (daysLeft !== 1 ? "s" : "") + ' overdue';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check appraisal
  function checkAppraisal() {
    //console.log("checkAppraisal");
    if (loanData["Milestone Date - Processing"].length !== 0) {
      if(loanData["Document Date Received - Appraisal Waiver"].length !== 0) {
        return;
      } else if (loanData["Document Date Ordered - Appraisal"].length === 0) {
        messageTxt = 'Order Appraisal';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
        return;
      } else if (loanData["Document Date Received - Appraisal"].length === 0) {
        let daysLimit = 10;
        if (daysToClose <= daysLimit) {
          messageTxt = 'Appraisal not received';
          tempWarningArray.push({id: warnID++, message: messageTxt});
        } else {
          return;
        }
      } else {
        return;
      }
    }
  }

  // Check Title
  function checkTitle() {
    //console.log("checkTitle");
    if (loanData["Milestone Date - Processing"].length !== 0) {
      if (loanData["Document Date Ordered - 04-Title"].length === 0) {
        messageTxt = 'Order title';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
        return;
      } else if (loanData["Document Date Received - 04-Title"].length === 0) {
          let daysLimit = 10;
          if (daysToClose <= daysLimit) {
            messageTxt = 'Title not received';
            tempWarningArray.push({id: warnID++, message: messageTxt});
          }
      } else {
        return;
      }
    }
  }

  // Check USPS
  function checkUSPS() {
    //console.log("checkUSPS");
    if (loanData["Milestone Date - Processing"].length !== 0) {
      if (loanData["Document Date Received - USPS"].length === 0) {
          messageTxt = 'USPS not received';
          tempNoticeArray.push({id: noticeID++, message: messageTxt});
      }
    }
  }

  // Check HOI
  function checkHOI() {
    //console.log("checkHOI");
    if (loanData["Document Date Received - Homeowners Insurance Policy"].length === 0) {
      let daysLimit = 10;

      if (daysToClose <= daysLimit) {
        messageTxt = 'HOI not received';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        return;
      }
    }
  }

  // Check VOE
  function checkVOE() {
    //console.log("checkVOE");
    if (loanData[ 'Document Date Received - Income - VOE - Final' ].length === 0) {
      let daysLimit = 10;

      if (daysToClose <= daysLimit && daysToClose > 1) {
        messageTxt = 'Final VOE is due in ' + daysToClose + ' days';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else if (daysToClose < 2) {
        messageTxt = 'Final VOE is due today';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else {
        return;
      }
    }
  }

  // Check transcripts (Borrower is Self Employed)
  function checkTranscripts() {
    //console.log("checkTranscripts");
    if (loanData["Borr Self Employed"] === "Y") {
      if (loanData["TIL Intl TIL Disclosure Rcvd Date"].length === 0 && loanData["Document Date Ordered - 4506T Settlement Service"].length === 0 ) {
        let daysLimit = 5;
        let leRcvDate = new Date(loanData["TIL Intl TIL Disclosure Rcvd Date"]);
        let tempDate = leRcvDate;
        let transDueDate = new Date(tempDate.setDate(tempDate.getDate() + daysLimit));
        //console.log("Fubar due date: ", transDueDate);

        if (currentDate.getTime() <= transDueDate.getTime()) {
          messageTxt = 'Order transcripts';
          tempNoticeArray.push({id: noticeID++, message: messageTxt});
        } else {
          messageTxt = 'Order transcripts are overdue';
          tempWarningArray.push({id: warnID++, message: messageTxt});
        }
      }
    } else {
      return;
    }
  }

  // Check VA
  function checkVA() {
    //console.log("checkVA");
    if (loanData["Loan Type"] === "VA" && loanData["Document Date Received - VA Certificate of Eligibility/COE"].length === 0 ) {
      messageTxt = 'Order VA COE';
      tempNoticeArray.push({id: noticeID++, message: messageTxt});
    }
  }

  // Check processing to underwriting
  function checkProcToUW() {
    //console.log("checkProcToUW");
    if (loanData["Milestone Date - Processing"].length !== 0 && loanData["Milestone Date - Submittal"].length === 0 ) {
      let daysLimit = 1;
      let procDate = new Date(loanData["Milestone Date - Processing"]);
      let tempDate = procDate;
      let uwDueDate = new Date(tempDate.setDate(tempDate.getDate() + daysLimit));

      if (currentDate.getTime() <= uwDueDate.getTime()) {
        messageTxt = 'Submit to underwriting';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        messageTxt = 'Submit to underwriting is overdue';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check underwriting
  function checkUW() {
    //console.log("checkUW");
    if (loanData["Milestone Date - Approval"].length === 0 && loanData["Milestone Date - Submittal"].length !== 0) {
      let daysLimit = 3;
      let tempDate = new Date(loanData["Milestone Date - Submittal"]);;
      let cdchkDueDate = new Date(tempDate.setDate(tempDate.getDate() + daysLimit));

      if (currentDate.getTime() >= cdchkDueDate.getTime()) {
        messageTxt = 'Check with UW on approval';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      }
    }
  }

  // Check conditions sent to borrower
  function checkCondToBor() {
    //console.log("checkCondToBor");
    if (loanData["Milestone Date - Approval"].length === 0 && loanData["Milestone Date - Cond Approved"].length !== 0 && loanData["Document Date Received - 05-Conditions"].length === 0 && loanData["Document Date Ordered - 05-Conditions"].length === 0 ) {
      let daysLimit = 1;
      let condApprDate = new Date(loanData["Milestone Date - Cond Approved"]);
      let tempDate = condApprDate;
      let sendCondDueDate = new Date(tempDate.setDate(tempDate.getDate() + daysLimit));

      if (currentDate.getTime() <= sendCondDueDate.getTime()) {
        messageTxt = 'Conditions to borrower';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        messageTxt = 'Conditions to borrower are overdue';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check conditions received from borrower
  // function checkCondFromBor() {
  //   if (loanData["Milestone Date - Approval"].length === 0 && loanData["Milestone Date - Cond Approved"].length !== 0 && loanData["Document Date Ordered - 05-Conditions"].length !== 0 && loanData["Document Date Received - 05-Conditions"].length === 0) {
  //     let daysLimit = 10;
  //     let tempDate = closeDate;
  //     let condDueDate = new Date(tempDate.setDate(tempDate.getDate() - daysLimit));

  //     if (currentDate.getTime() >= condDueDate.getTime()) {
  //       messageTxt = 'Conditions have not been received';
  //       setNoticeArray([
  //         ...noticeArray,
  //         { id: uniqueID, message: messageTxt }
  //       ]);
  //     }
  //   }
  // }

  // Check CTC
  function checkCTC() {
    //console.log("checkCTC");
    if (loanData["Milestone Date - Submittal"].length !== 0 && loanData["Milestone Date - Approval"].length === 0) {
      let noticeDaysLimit = 5;
      let alertDaysLimit = 3;
      let noticeTempDate = new Date(closeDate);
      let alertTempDate = new Date (closeDate);
      let ctcNoticeDueDate = new Date(noticeTempDate.setDate(noticeTempDate.getDate() - noticeDaysLimit));
      let ctcAlertDueDate = new Date(alertTempDate.setDate(alertTempDate.getDate() - alertDaysLimit));

      if (currentDate.getTime() >= ctcAlertDueDate.getTime()) {
        messageTxt = 'CTC not received';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (currentDate.getTime() >= ctcNoticeDueDate.getTime()) {
        messageTxt = 'CTC not received';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        return;
      }
    }
  }

  // Check CD Ready
  function checkCDReady() {
    //console.log("checkCDReady");
    if (loanData["Closing Disclosure Sent Date"].length === 0 && loanData["Document Date Received - Appraisal"].length !== 0 && loanData["Document Date Received - 04-Title"].length !== 0 && loanData["Document Date Received - Homeowners Insurance Policy"].length !== 0) {
      cdReady = true;
      messageTxt = 'Send CD';
      tempNoticeArray.push({id: noticeID++, message: messageTxt});
    }
  }

  // Check CD Sent
  function checkCDSent() {
    //console.log("checkCDSent");
    if (cdReady && loanData["Closing Disclosure Sent Date"].length === 0) {
      let noticeDaysLimit = 2; // days after CD ready
      let alertDaysLimit = 5; // days prior to close
      let noticeTempDate = new Date();
      let alertTempDate = new Date (closeDate);

      let appraisalDate = new Date(loanData["Document Date Received - Appraisal"]);
      let titleDate = new Date(loanData["Document Date Received - 04-Title"]);

      if (appraisalDate.getTime() >= titleDate.getTime()) {
        noticeTempDate = new Date(appraisalDate);
      } else {
        noticeTempDate = new Date(titleDate);
      }

      let cdSentNoticeDueDate = new Date(noticeTempDate.setDate(noticeTempDate.getDate() + noticeDaysLimit));
      let cdSentAlertDueDate = new Date(alertTempDate.setDate(alertTempDate.getDate() - alertDaysLimit));

      if (currentDate.getTime() >= cdSentAlertDueDate.getTime()) {
        messageTxt = 'CD not sent';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (currentDate.getTime() >= cdSentNoticeDueDate.getTime()) {
        messageTxt = 'CD not sent';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        return;
      }
    }
  }

  // Check CD received
  function checkCDReceived() {
    //console.log("checkCDReceived");
    if (loanData["Closing Disclosure Sent Date"].length !== 0 && loanData["Closing Disclosure Received Date"].length === 0) {
      let noticeDaysLimit = 1; // days after CD sent
      let alertDaysLimit = 5; // days prior to close
      let noticeTempDate = new Date(loanData["Closing Disclosure Sent Date"]);
      let alertTempDate = new Date (closeDate);
      let cdReceivedNoticeDate = new Date(noticeTempDate.setDate(noticeTempDate.getDate() + noticeDaysLimit));
      let cdReceivedAlertDate = new Date(alertTempDate.setDate(alertTempDate.getDate() - alertDaysLimit));

      //console.log("CD received due date: ", cdReceivedNoticeDate);
      //console.log("CD received due date: ", cdReceivedAlertDate);


      if (currentDate.getTime() >= cdReceivedAlertDate.getTime()) {
        messageTxt = 'CD not received';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (currentDate.getTime() >= cdReceivedNoticeDate.getTime()) {
        messageTxt = 'CD not received';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else {
        return;
      }
    }
  }

  // Check Closing package ordered
  function checkClosingPkgOrder() {
    //console.log("checkClosingPkgOrder");
    if (loanData["Milestone Date - Approval"].length !== 0) {
      if (loanData["Closing Disclosure Received Date"].length !== 0 && loanData["Milestone Date - Closing Pkg Sent"].length === 0) {
        let noticeDaysLimit = 5; // days prior to close
        let noticeTempDate = new Date(closeDate);
        let orderPkgNoticeDate = new Date(noticeTempDate.setDate(noticeTempDate.getDate() - noticeDaysLimit));

        if (currentDate.getTime() >= orderPkgNoticeDate.getTime()) {
          messageTxt = 'Send final closing package';
          tempNoticeArray.push({id: noticeID++, message: messageTxt});
        }
      }
    }
  }

  // Check Closing package sent
  function checkClosingPkgSent() {
    //console.log("checkClosingPkgSent");
    if (loanData["Milestone Date - Approval"].length !== 0) {
        if (loanData["Closing Disclosure Received Date"].length !== 0 && loanData["Milestone Date - Closing Pkg Sent"].length === 0) {
        let noticeDaysLimit = 3; // days prior to close
        let noticeTempDate = new Date(closeDate);
        let closingPkgNoticeDate = new Date(noticeTempDate.setDate(noticeTempDate.getDate() - noticeDaysLimit));

        if (currentDate.getTime() >= closingPkgNoticeDate.getTime()) {
          messageTxt = 'Send closing package';
          tempNoticeArray.push({id: noticeID++, message: messageTxt});
        } 
      }
    }
  }

  // Check Note sent to investor
  // function checkNoteSent() {
  //   if (loanData["Milestone Date - Funding"].length !== 0) {
  //     messageTxt = 'Send note/allonge to investor';
  //     setWarningArray([
  //       ...warningArray,
  //       { id: uniqueID, message: messageTxt }
  //     ]);
  //   }
  // }

  // Check Upload closing package to investor
  // function checkPkgUpload() {
  //   if (loanData["Milestone Date - Funding"].length !== 0) {
  //     messageTxt = 'Upload closing package to investor';
  //     setWarningArray([
  //       ...warningArray,
  //       { id: uniqueID, message: messageTxt }
  //     ]);
  //   }
  // }

  // Check MERS registration
  function checkMERS() {
    //console.log("checkMERS");
    if (loanData["File Contacts Broker Name"].trim() !== "") {
      return;
    } else if (loanData["Milestone Date - Funding"].length !== 0 && loanData["Shipping MERS Registration Date"].length === 0) {
      messageTxt = 'Register MERS';
      tempWarningArray.push({id: warnID++, message: messageTxt});
    }
  }

  // Check appraisal contingency
  function checkAppraisalCont() {
    //console.log("checkAppraisalCont");
    if (loanData["Appraisal Contingency Date"].trim() !== "") {
      let appraisalContDate = new Date(loanData["Appraisal Contingency Date"]);
      //console.log("appraisal cont due date: ", appraisalContDate);

      daysLeft = getDayDiff(appraisalContDate);
      //console.log("Appraisal days left: ", daysLeft);

      if (daysLeft > 1) {
        messageTxt = 'Appraisal contingency expires in ' + daysLeft + ' days';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else if (daysLeft === 1) {
        messageTxt = 'Appraisal contingency expires in ' + daysLeft + ' day';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (daysLeft === 0) {
        messageTxt = 'Appraisal contingency expires today';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else {
        messageTxt = 'Appraisal contingency has expired';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check financing contingency
  function checkFinancingCont() {
    //console.log("checkFinancingCont");
    if (loanData["Financing Contingency Date"].trim() !== "") {
      let financiallContDate = new Date(loanData["Financing Contingency Date"]);
      //console.log("financing contingency due date: ", financiallContDate);

      daysLeft = getDayDiff(financiallContDate);
      //console.log("days left: ", daysLeft);

      if (daysLeft > 1) {
        messageTxt = 'Financing contingency expires in ' + daysLeft + ' days';
        tempNoticeArray.push({id: noticeID++, message: messageTxt});
      } else if (daysLeft === 1) {
        messageTxt = 'Financing contingency expires in ' + daysLeft + ' day';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else if (daysLeft === 0) {
        messageTxt = 'Financing contingency expires today';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      } else {
        messageTxt = 'Financing contingency has expired';
        tempWarningArray.push({id: warnID++, message: messageTxt});
      }
    }
  }

  // Check Lock
  function checkLock() {
    //console.log("checkLock");
    if (loanData["Lock Date"].length === 0) {
          let daysLimit = 10;
          if (daysToClose <= daysLimit) {
            messageTxt = 'Loan is not locked';
            tempWarningArray.push({id: warnID++, message: messageTxt});
          }
    }
  }

  function checkLoanRules() {
    if (loanData["Last Finished Milestone"] !== "Funded") {
      checkRESPA();
      checkAppraisal();
      checkTitle();
      checkHOI();
      checkUSPS();
      checkVOE();
      checkTranscripts();
      checkVA();
      checkProcToUW();
      checkUW();
      checkCondToBor();
      checkCTC();
      checkCDReady();
      checkCDSent();
      checkCDReceived();
      checkClosingPkgOrder();
      checkClosingPkgSent();
      //checkNoteSent(); disabled
      //checkPkgUpload(); disabled
      checkLockExp();
      checkAppraisalCont();
      checkFinancingCont();
      checkLock();
    } else {
      checkMERS();
    }
    //console.log("Alert Array: ", warningArray);
    //console.log("Notice Array: ", noticeArray);
  }

  return (
    <>
    {warningArray.length > 0 && (
      <Alert severity="warning">
        <AlertTitle sx={{fontWeight: "700"}}>Warning</AlertTitle>
        {warningArray.map((warnings) => (
          <Typography key={warnings.id}>{warnings.message}</Typography>
        ))}
      </Alert>
    )}
    {noticeArray.length > 0 && (
      <Alert severity="info">
        <AlertTitle sx={{fontWeight: "700"}}>Notice</AlertTitle>
        {noticeArray.map((notices) => (
          <Typography key={notices.id}>{notices.message}</Typography>
        ))}
      </Alert>
    )}
    </>
  );

}